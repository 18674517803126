import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      linaImage: file(relativePath: { eq: "lina_profile_bw_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <div className="antialised">
        <div className="relative bg-white">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <Img
                className="w-full object-cover lg:absolute lg:h-full"
                fluid={data.linaImage.childImageSharp.fluid}
                alt=""
              />
            </div>
          </div>
          <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2 lg:pl-8">
              <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                <p className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                  Nyheter
                </p>
                <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Vi utökar!
                </h1>
                <p className="text-lg leading-7 text-gray-500 mb-5">
                  Vi välkomnar <strong>Lina Järbing </strong>till Studio Ess!{" "}
                  <span role="img" aria-label="Star emoji">
                    ✨
                  </span>
                </p>
                <div className="text-gray-500">
                  <p className="prose">
                    Från och med september finns hon hos oss för att göra Lash
                    lift och Brow lamination - helt fantastiska behandlingar!
                    Men redan nu har du möjlighet att boka din tid. Klicka på
                    "Boka tid" uppe till höger eller ring oss på 0651-300555.
                  </p>
                  <div className="mt-1">
                    <p className="text-base leading-7 ">
                      Vill du läsa mer om{" "}
                      <strong className="text-gray-500">Lash lift?</strong>{" "}
                      <Link
                        to="/lashlift"
                        className="text-indigo-600 font-semibold no-underline"
                      >
                        Klicka här!
                      </Link>
                    </p>
                    <p className="text-base leading-7 ">
                      Vill du läsa mer om{" "}
                      <strong className="text-gray-500">
                        Brow lamination?
                      </strong>{" "}
                      <Link
                        to="/browlamination"
                        className="text-indigo-600 font-semibold no-underline"
                      >
                        Klicka här!
                      </Link>
                    </p>
                  </div>
                  <p className="prose mt-2">
                    Vi ser fram emot en spännande höst!{" "}
                    <span role="img" aria-label="Star emoji">
                      ✨
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News
